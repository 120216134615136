import * as styles from "./SkipToContent.module.scss"

import { PropTypes } from "prop-types"
import React from "react"

/**
 * Returns a button that when clicked, scrolls the user to the main content. Only accessible via the keyboard.
 * @param {object} contentRef A ref to the dom-object that will be scrolled into view
 *
 */

const SkipToContent = ({ contentRef }) => {
  const scrollToContent = contentRef => {
    contentRef.current.scrollIntoView()
  }
  return (
    <button
      className={styles.skip}
      onClick={() => {
        scrollToContent(contentRef)
      }}
    >
      Skip to content
    </button>
  )
}

SkipToContent.propTypes = {
  contentRef: PropTypes.object.isRequired,
}

SkipToContent.defaultProps = {
  contentRef: null,
}

export default SkipToContent
