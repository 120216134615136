import { Helmet } from "react-helmet"
import React from "react"

const Tracker = () => {
  const debug = process.env.GATSBY_ENVIRONMENT !== "production"
  if (debug) {
    console.log("Script loaded")
    return null
  } else {
    return (
      <Helmet>
        <script
          src="//assets.adobedtm.com/launch-ENa5ce79481e254e549763665a44a805b5.min.js"
          async
        ></script>
      </Helmet>
    )
  }
}

export default Tracker
