/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"

import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import React from "react"

function SEO({ description, lang, meta, title, image, article }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image
          }
        }
      }
    `
  )

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const metaImage = image || site.siteMetadata.image
  const metaType = article === true ? "article" : "website"
  const defaultTitle = site.siteMetadata?.title
  const debug = process.env.GATSBY_ENVIRONMENT !== "production"
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      description={metaDescription}
      titleTemplate={defaultTitle ? `%s | DNB` : null}
      meta={[
        {
          name: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: metaType,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `twitter:card`,
          content: `summary`,
        },
        {
          property: `twitter:creator`,
          content: site.siteMetadata.twitterHandle,
        },
        {
          property: `twitter:creator`,
          content: site.siteMetadata.twitterHandle,
        },
      ].concat(meta)}
    >
      {debug && <meta name="robots" content="noindex" />}
      <meta name="description" content={metaDescription} />
      <meta name="og:image" content={metaImage} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  article: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

export default SEO
