import "dnb-ui-lib/style"

import React, { useEffect, useRef, useState } from "react"

import Nav from "../nav/Nav"
import PropTypes from "prop-types"
import { Skeleton } from "dnb-ui-lib"
import SkipToContent from "../skip-to-content/SkipToContent"
import Tracker from "../tracker/Tracker"
import classNames from "classnames"
import { pageLoad } from "../../utils/track"
import * as styles from "./Layout.module.scss"

/**
 * Returns the outer-most wrapper of pages and templates, also containing the Nav
 * @param {overlay} bool Specifies if the header should be displayed relative or absolute
 * @param {fixed} bool Specifies if the header should be displayed fixed
 *
 */

const Layout = ({ children, overlay, fixed }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const mainRef = useRef(null)
  useEffect(() => {
    mainRef.current.inert = menuOpen
  }, [menuOpen])

  useEffect(() => {
    pageLoad()
  }, [])

  return (
    <Skeleton show={false}>
      <Tracker />
      <div
        className={classNames(styles.content, { [styles.hidden]: menuOpen })}
      >
        <SkipToContent contentRef={mainRef} />
        <Nav
          overlay={overlay}
          contentRef={mainRef}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          showMenu={true}
          fixed={fixed}
        />
        <main className={styles.main} ref={mainRef}>
          {children}
        </main>
      </div>
    </Skeleton>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  overlay: PropTypes.bool,
  fixed: PropTypes.bool,
}

Layout.defaultProps = {
  children: null,
  overlay: false,
  fixed: false,
}

export default Layout
