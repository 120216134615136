const linkMaker = linkTo => {
  if (linkTo) {
    switch (linkTo._type) {
      case "linkHref":
        return {
          internal: false,
          location: linkTo.href,
        }
      case "articleFeature":
        return {
          internal: true,
          location: `/${linkTo.slug.current}`,
        }
      case "articleBasic":
        return {
          internal: true,
          location: `/${linkTo.slug.current}`,
        }
      case "citations":
        return {
          internal: true,
          location: "/kilder",
        }
      case "visualiser":
        return {
          internal: true,
          location: "/ringvirkninger",
        }
      case "pageFond":
        return {
          internal: true,
          location: "/miljofond",
        }
      case "pageLanding":
        return {
          internal: true,
          location: "/",
        }

      default:
        return {
          internal: true,
          location: "/",
        }
    }
  }
}

export default linkMaker
