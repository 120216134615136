import "wicg-inert"

import * as styles from "./Nav.module.scss"

import { Anchor, Heading, Icon, Logo } from "dnb-ui-lib"
import React, { useRef } from "react"
import { StaticQuery, graphql } from "gatsby"

import { close as Close } from "dnb-ui-lib/icons"
import { hamburger as Hamburger } from "dnb-ui-lib/icons"
import { Link } from "gatsby"
import { PropTypes } from "prop-types"
import classNames from "classnames"
import linkMaker from "../../utils/linkMaker"
import { navigate } from "gatsby"
import whatInput from "what-input"

/**
 * Returns the navigation, using a staticQuery to get menu data from Sanity
 * @param {overlay} bool Specifies if the header should be displayed relative or absolute
 * @param {fixed} bool Indicates if the menu is using fixed position
 * @param {menuOpen} bool Indicates if the menu is open
 * @param {setMenuOpen} function Sets the menuOpen value
 * @param {showMenu} function Indicates if openable menu will be shown
 *
 */

const Nav = ({ overlay, fixed, menuOpen, setMenuOpen, showMenu }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          allSanityNavigation {
            edges {
              node {
                menuItems {
                  _key
                  text
                  linkTo {
                    ... on SanityPageLanding {
                      _type
                    }
                    ... on SanityLinkHref {
                      _type
                      href
                    }
                    ... on SanityArticleFeature {
                      _type
                      slug {
                        current
                      }
                    }
                    ... on SanityArticleBasic {
                      _type
                      slug {
                        current
                      }
                    }
                    ... on SanityCitations {
                      id
                      _type
                    }
                    ... on SanityVisualiser {
                      id
                      _type
                    }
                    ... on SanityPageFond {
                      _type
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <NavElement
          data={data}
          overlay={overlay}
          fixed={fixed}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          showMenu={showMenu}
        />
      )}
    />
  )
}

const NavElement = ({
  overlay,
  fixed,
  data,
  menuOpen,
  setMenuOpen,
  showMenu,
}) => {
  const menuData = data.allSanityNavigation.edges[0].node
  const menuOpenButton = useRef(null)
  const menuCloseButton = useRef(null)
  const linksRef = useRef(null)
  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
    if (whatInput.ask() === "keyboard") {
      if (menuOpen === true) {
        menuOpenButton.current.focus()
      } else {
        linksRef.current.children[0].focus()
      }
    }
  }
  const links = menuData.menuItems.map(({ _key, text, linkTo }, index) => {
    const linkData = linkMaker(linkTo[0])
    if (linkData.internal === true) {
      return (
        <Link
          to={linkData.location}
          className={styles.link}
          key={_key}
          tabIndex={!menuOpen ? "-1" : index + 2}
        >
          {text}
        </Link>
      )
    }
    return (
      <Anchor
        href={linkData.location}
        className={styles.link}
        key={_key}
        tabIndex={!menuOpen ? "-1" : index + 2}
      >
        {text}
      </Anchor>
    )
  })
  if (!showMenu) {
    return (
      <div className={classNames(styles.menu, { [styles.fixed]: fixed })}>
        <div
          className={classNames(styles.topMenu, { [styles.negative]: overlay })}
        >
          <Link to="/" aria-label={"Til forsiden"}>
            <div className={styles.logoContainer}>
              <Logo size="auto" className={styles.logo} />
            </div>
          </Link>
        </div>
      </div>
    )
  }
  return (
    <div className={classNames(styles.menu, { [styles.fixed]: fixed })}>
      <div
        className={classNames(styles.topMenu, { [styles.negative]: overlay })}
      >
        <Link to="/" aria-label={"Til forsiden"}>
          <div className={styles.logoContainer}>
            <Logo size="auto" className={styles.logo} />
          </div>
        </Link>
        <button
          className={styles.menuToggle}
          onClick={() => toggleMenu()}
          aria-label={"Åpne menyen"}
          ref={menuOpenButton}
        >
          <span className={styles.label}>Meny</span>
          <Icon
            icon={Hamburger}
            size={"medium"}
            className={styles.icon}
            aria-hidden
          />
        </button>
      </div>
      <button
        className={classNames(styles.overlay, { [styles.open]: menuOpen })}
        tabIndex={-1}
        aria-label={"Lukk menyen"}
        onClick={() => {
          setMenuOpen(false)
        }}
      />
      <nav className={classNames(styles.nav, { [styles.open]: menuOpen })}>
        <button
          className={styles.menuToggle}
          onClick={() => toggleMenu()}
          aria-label={"Lukk menyen"}
          tabIndex={!menuOpen ? "-1" : 1}
          ref={menuCloseButton}
        >
          <span className={styles.label}>Lukk</span>
          <Icon
            icon={Close}
            size={"medium"}
            className={styles.icon}
            aria-hidden
          />
        </button>
        <div className={styles.navContent}>
          <Heading className={styles.title}>Meny</Heading>
          <div className={styles.linkContainer} ref={linksRef}>
            {links}
          </div>
        </div>
      </nav>
    </div>
  )
}

Nav.propTypes = {
  overlay: PropTypes.bool,
  fixed: PropTypes.bool,
  showMenu: PropTypes.bool,
}

Nav.defaultProps = {
  overlay: false,
  fixed: false,
  showMenu: true,
}

export default Nav
