const debug = process.env.GATSBY_ENVIRONMENT !== "production"

export function track(type, action, name, value) {
  // track("Video", "Play", "DNB_play_video", "10")
  if (debug) {
    console.log({
      event: "GenericEvent",
      type: type,
      action: action,
      name: name,
      value: value,
    })
    return
  }

  if (window.dnbDataLayer) {
    try {
      window.dnbDataLayer.push({
        event: "GenericEvent",
        event_type: type,
        event_action: action,
        event_name: name,
        event_value: value,
      })
    } catch (error) {
      console.error(error)
    }
  }
}

export function pageLoad() {
  if (debug) {
    console.log("pageload", window.location)
    return
  }

  if (window.dnbDataLayer) {
    try {
      window.dnbDataLayer.push({
        event: "PageLoaded",
        page: {},
      })
    } catch (error) {
      console.log(error)
    }
  }
}
