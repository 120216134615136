// extracted by mini-css-extract-plugin
export const content = "Layout-module--content--1bv1d";
export const hidden = "Layout-module--hidden--3gG4r";
export const main = "Layout-module--main--Xsalf";
export const dnbCoreStyle = "Layout-module--dnb-core-style--ogEAh";
export const dnbHBasis = "Layout-module--dnb-h--basis--3XoAZ";
export const dnbHLarge = "Layout-module--dnb-h--large--1RIMQ";
export const dnbHMedium = "Layout-module--dnb-h--medium--OQw5B";
export const dnbHSmall = "Layout-module--dnb-h--small--3GO-A";
export const dnbHXLarge = "Layout-module--dnb-h--x-large--1Oyjn";
export const dnbHXSmall = "Layout-module--dnb-h--x-small--czllE";
export const dnbHXxLarge = "Layout-module--dnb-h--xx-large--3AmGM";
export const dnbLead = "Layout-module--dnb-lead--2FS5-";